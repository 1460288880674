import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },{
    path:"/trad",
    name:"trading",
    component:()=>import('../views/Tradingview.vue')
  },{
    path:"/virtor",
    name:"艺术家及作品信息展示",
    component:()=>import('../views/VirtorView.vue')
  }
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
